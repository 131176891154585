<template>
  <div>
    <div class="content" v-if="!showTag">
      <div class="detaile">
        <div class="left">
          <div class="flex-row">
            <div class="title"><span style="color:red">*</span>筛选店铺会员：</div>
            <div>
              共有<span style="margin: 0 20px; color: #5B69FE; font-weight: bold; cursor: pointer;" @click="check">{{ detail.externalUserid.length
                }}</span>会员符合筛选条件
            </div>
          </div>
          <div class="flex-row">
            <div class="title">添加小程序：</div>
            {{ detail.page }}
          </div>
          <div class="flex-row" style="flex-wrap: wrap;">
            <div class="title">小程序封面：</div>
            <viewer :images="detail.coverImage" style="display: flex;flex-wrap: wrap;">
              <div class="images-item" v-for="(item, index) in detail.coverImage" :key="index">
                <img :src="item.url" :alt="item.name">
              </div>
            </viewer>
          </div>
          <div class="flex-row" style="flex-wrap: wrap;">
            <div class="title">图片：</div>
            <viewer :images="detail.images" style="display: flex;flex-wrap: wrap;">
              <div class="images-item" v-for="(item, index) in detail.images" :key="index">
                <img :src="item.url" :alt="item.name">
              </div>
            </viewer>
          </div>
          <div class="flex-row">
            <div class="title">发送时间：</div>
            {{ detail.sendDate + ' ' + detail.sendTime }}
          </div>
        </div>
        <div class="right">
          <div class="flex-row">
            <div class="title">标题：</div>
            <span style="font-weight: 600;">{{ detail.title }}</span>
          </div>
          <div class="flex-row" style="align-items: self-start;flex:1">
            <div class="title">内容：</div>
            <div class="content-content ql-editor" v-html="detail.content">
            </div>
          </div>
        </div>
      </div>
      <div class="btn">
        <el-button @click="back">返回</el-button>
      </div>
    </div>
    <tag-group-detail v-if="showTag" @cancel="cancel" :externalUserid="detail.externalUserid"
      :tagsList="detail.tagsList"></tag-group-detail>
  </div>
</template>

<script>
import TagGroupDetail from "./tagGroupDetail";

export default {
  name: "detail",
  components: {
    TagGroupDetail
  },
  data () {
    return {
      showTag: false,
      detail: {}
    }
  },
  props: {
    detailInfo: {
      type: Object,
      default () {
        return {
        };
      },
    },
  },
  watch: {
    detailInfo: {
      immediate: true,
      deep: true,
      handler (v) {
        if (v) {
          this.getDetail(v)
        }
      }
    }
  },
  methods: {
    check() {
      this.showTag = true
    },
    cancel () {
      this.showTag = false
    },
    getDetail (data) {
      let detail = {
        title: data.title,
        externalUserid: data.externalUserid,
        sendDate: data.sendDate,
        sendTime: data.sendTime,
      }
      if (data.filterContent) {
        detail.images = data.filterContent.images
        detail.coverImage = data.filterContent.coverImage
        detail.tagsList = data.filterContent.tagsList
      }
      if (data.msgContent) {
        if (data.msgContent.text) {
          detail.content = data.msgContent.text.content
        }
        if (data.msgContent.attachments) {
          let miniprogram = data.msgContent.attachments.find(item => item.msgtype == 'miniprogram')
          if (miniprogram && miniprogram.miniprogram) {
            detail.page = miniprogram.miniprogram.page
          }
        }
      }
      this.detail = detail
    },
    back () {
      this.$emit("changeType", 'list');
    },
  }
}
</script>

<style scoped lang="scss">
.content {
  background: #fff;
  padding: 30px 20px;

  .detaile {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .left {
    flex: 1;
    margin-right: 20px;
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .flex-row {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding-bottom: 30px
  }

  &::v-deep .el-dialog {
    overflow-y: auto;
  }

  &::v-deep .el-dialog__body {
    height: 750px;
    max-height: 750px;
    overflow-y: auto;
  }

  .conten {
    flex: 1;
    border: solid 1px #D9D9D9;
    min-height: 100px;
    max-height: 200px;
    overflow: auto;
    padding: 10px;
    margin-left: 50px;
    // display: flex;
  }

  .content-content {
    flex: 1;
    border: solid 1px #D9D9D9;
    height: 100%;
    overflow: auto;
    padding: 20px;
    line-height: 20px;
  }

  .conten-item {
    display: inline-block;
    color: #000;
    background: #F2F2F2;
    padding: 5px 15px;
    border-radius: 20px;
    margin-bottom: 10px;
  }

  .title {
    width: 120px;
    text-align: right;
    flex-shrink: 0;
  }

  .wordfile {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.2);

    iframe {
      margin-left: 10%;
      margin-top: 3%;
    }
  }

  .images-item {
    width: 150px;
    height: 100px;
    margin-left: 10px;
    margin-top: 5px;
    position: relative;
  }

  .images-item img {
    width: 100%;
    height: 100%;
  }

  .files-item {
    margin-left: 10px;
    margin-top: 5px;
    position: relative;
  }

  .file-img {
    display: flex;
    flex-direction: column;
    // align-items: center;
  }

  .file-img img {
    width: 70px;
    height: 70px;
  }

  .file-img div {
    max-width: 73px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
  }

  .btn {
    position: fixed;
    bottom: 0;
    background-color: white;
    left: 262px;
    display: flex;
    width: calc(100% - 282px);
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    z-index: 10;
  }
}
</style>