<template>
  <div class="content">
    <div v-for="(groupItem, index) in tagsList" class="zidingyimemberGroup">
      <div class="topTitle clearfix" style="margin-bottom: 20px">
        <div class="left fl" style="padding: 0px">
          {{ groupItem.name }}
        </div>
      </div>
      <table>
        <template v-for="(tagsItem, tagsIndex) in groupItem.children">
          <tr>
            <td class="title" style="
                padding: 5px 0px 5px 10px;
                height: 40px;
                vertical-align: middle;
                width: 120px;
              ">
              {{ tagsItem.name }}
            </td>
            <td style="padding: 12px 0px; height: 40px; vertical-align: middle">
              <template v-if="tagsItem.tagType === '1'">
                <el-radio label="0" v-model="tagsItem.itemModel" style="margin-left: 5px" disabled>不限</el-radio>
                <template v-for="(sItem, sIndex) in tagsItem.children">
                  <el-radio :label="sItem.id" v-model="tagsItem.itemModel" disabled>{{ sItem.name }}</el-radio>
                </template>
              </template>
              <template v-if="tagsItem.tagType === '2'">
                <el-row type="flex">
                  <el-checkbox class="radio" label="0" style="margin-left: 12px; margin-right: 40px"
                    v-model="tagsItem.itemBuXianModel" disabled>不限</el-checkbox>
                  <el-checkbox-group v-model="tagsItem.itemModel">
                    <el-col v-for="sItem in tagsItem.children" :key="sItem.code" :span="2" style="min-width: 120px">
                      <el-checkbox :label="sItem.code" disabled :title="sItem.name">
                        {{ sItem.name }}
                      </el-checkbox>
                    </el-col>
                  </el-checkbox-group>
                </el-row>
              </template>
              <template v-if="tagsItem.tagType === '3'">
                <el-checkbox class="radio" label="0" style="margin-left: 12px; margin-right: 20px"
                  v-model="tagsItem.itemBuXianModel" disabled>不限</el-checkbox>
                <template v-if="tagsItem.code === 'birthday_day'">
                  <el-input v-model="tagsItem.children[0].minValue" placeholder="" :controls="false" disabled
                    style="width: 150px; margin-left: 20px"></el-input>
                  <em style="margin-left: 10px; margin-right: -10px">-</em>
                  <el-input v-model="tagsItem.children[0].maxValue" placeholder="" :controls="false" disabled
                    style="width: 150px; margin-left: 20px"></el-input>
                  <em style="padding-left: 10px">{{ tagsItem.children[0].unit }}</em>
                  <template v-if="tagsItem.children[0].tip">
                    <span class="membertips">{{ tagsItem.children[0].tip }}</span>
                  </template>
                </template>
                <template v-else>
                  <el-input-number v-model="tagsItem.children[0].minValue" placeholder="" :controls="false" disabled
                    style="width: 150px; margin-left: 20px"></el-input-number>
                  <em style="margin-left: 10px; margin-right: -10px">-</em>
                  <el-input-number v-model="tagsItem.children[0].maxValue" placeholder="" :controls="false" disabled
                    style="width: 150px; margin-left: 20px"></el-input-number>
                  <em style="padding-left: 10px">{{ tagsItem.children[0].unit }}</em>
                  <template v-if="tagsItem.children[0].tip">
                    <span class="membertips">{{ tagsItem.children[0].tip }}</span>
                  </template>
                </template>
              </template>
              <template v-if="tagsItem.tagType === '4'">
                <el-checkbox class="radio" label="0" style="margin-left: 12px; margin-right: 20px" disabled
                  v-model="tagsItem.itemBuXianModel">不限</el-checkbox>
                <template>
                  <el-input-number v-model="tagsItem.children[0].value" style="width: 150px; margin-left: 20px"
                    :controls="false" disabled></el-input-number>
                  <em style="padding-left: 10px">{{ tagsItem.children[0].unit }}</em>
                  <template v-if="tagsItem.children[0].tip">
                    <span class="membertips">{{ tagsItem.children[0].tip }}</span>
                  </template>
                </template>
              </template>
              <template v-if="tagsItem.tagType === '5'">
                <el-checkbox class="radio" label="0" style="margin-left: 12px; margin-right: 40px" disabled
                  v-model="tagsItem.itemBuXianModel">不限</el-checkbox>
                <template>
                  <el-select v-model="tagsItem.itemModel" multiple disabled>
                    <el-option v-for="item in tagsItem.children" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </template>
              </template>
            </td>
          </tr>
        </template>
      </table>
    </div>
    <div style="text-align: center;">
      <el-button @click="handleCancel">返回</el-button>
    </div>
  </div>
</template>
<script>

export default {
  data () {
    return {
    }
  },
  props: {
    externalUserid: {
      type: Array,
      default () {
        return [];
      },
    },
    tagsList: {
      type: Array,
      default () {
        return [];
      },
    },
  },
  methods: {
    handleCancel () {
      this.$emit('cancel')
    },
  },

}
</script>
<style scoped>
.content {
  height: 100%;
  overflow: auto;
  margin: 0 auto;
}

.input {
  width: 300px;
  height: 29px;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  text-indent: 8px;
}

table td {
  text-align: left;
}

div.zidingyimemberGroup table td.title {
  width: 95px;
}

div.zidingyimemberGroup table td input.input {
  width: 90px;
  margin: 0px 5px;
}

div.zidingyimemberGroup table td span {
  color: #898989;
}

.topTitle {
  padding: 8px 0;
  border-bottom: 1px solid #f2f2f2;
  height: 30px;
  margin: 20px 0;
  font-weight: bold;
  font-size: 15px;
}
</style>
